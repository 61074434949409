/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"

import HeaderContext from "../context/header-context"
import MotionWrapper from "../components/motion-wrapper"
import Container from "../components/container"
import Slab from "../components/slab"
import Copy from "../components/copy"
import SEO from "../components/seo"

const ContactPage = ({ data }) => {
  const {
    entry: [entry],
  } = data.craft

  const { updateHeaderState } = useContext(HeaderContext)

  useEffect(() => {
    updateHeaderState({
      section: "Contact",
      menuLinkHoverColor: "accent",
    })
  }, [updateHeaderState])

  return (
    <>
      <SEO
        description={entry.metaDescription}
        title={entry.metaTitle ? entry.metaTitle : entry.title}
        image={entry.metaImage[0].url}
      />
      <MotionWrapper>
        <Slab
          first
          bg="secondary"
          color="background"
          slabSx={{ display: "flex", pb: 1 }}
        >
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              alignItems: "stretch",
            }}
          >
            <Container>
              <Copy
                copySx={{
                  textAlign: "center",
                  fontSize: "2em",
                }}
                markup={`<p><a href="mailto:hello@madebymutual.com">hello@madebymutual.com</a></p><p>01424 559 858</p>`}
              />
            </Container>
          </div>
        </Slab>
      </MotionWrapper>
    </>
  )
}

export const ContactQuery = graphql`
  {
    craft {
      entry: entries(section: "contact", limit: 1) {
        title
        ... on craft_contact_contact_Entry {
          body
          legal
          metaTitle
          metaDescription
          metaImage {
            url
          }
        }
      }
    }
  }
`

export default ContactPage
